import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8c051cc0 = () => interopDefault(import('../pages/en/index.vue' /* webpackChunkName: "pages/en/index" */))
const _551a4716 = () => interopDefault(import('../pages/nl/index.vue' /* webpackChunkName: "pages/nl/index" */))
const _56746ac5 = () => interopDefault(import('../pages/en/animals.vue' /* webpackChunkName: "pages/en/animals" */))
const _bfd2ff28 = () => interopDefault(import('../pages/en/commissioned.vue' /* webpackChunkName: "pages/en/commissioned" */))
const _f5bc6f64 = () => interopDefault(import('../pages/en/Contact.vue' /* webpackChunkName: "pages/en/Contact" */))
const _0ab0e9fe = () => interopDefault(import('../pages/en/exposition.vue' /* webpackChunkName: "pages/en/exposition" */))
const _16e6c114 = () => interopDefault(import('../pages/en/giclees.vue' /* webpackChunkName: "pages/en/giclees" */))
const _35c89409 = () => interopDefault(import('../pages/en/landscape.vue' /* webpackChunkName: "pages/en/landscape" */))
const _40b138f2 = () => interopDefault(import('../pages/en/open-studio.vue' /* webpackChunkName: "pages/en/open-studio" */))
const _6722cc2d = () => interopDefault(import('../pages/en/paintings-applied.vue' /* webpackChunkName: "pages/en/paintings-applied" */))
const _8c929f9a = () => interopDefault(import('../pages/en/stil-life.vue' /* webpackChunkName: "pages/en/stil-life" */))
const _cd5b95ea = () => interopDefault(import('../pages/en/trompe-loeil.vue' /* webpackChunkName: "pages/en/trompe-loeil" */))
const _5dc0143e = () => interopDefault(import('../pages/en/video-johan-mulder.vue' /* webpackChunkName: "pages/en/video-johan-mulder" */))
const _ce305c3a = () => interopDefault(import('../pages/nl/Contact.vue' /* webpackChunkName: "pages/nl/Contact" */))
const _28ae9888 = () => interopDefault(import('../pages/nl/dieren.vue' /* webpackChunkName: "pages/nl/dieren" */))
const _d61ffa2a = () => interopDefault(import('../pages/nl/expositie.vue' /* webpackChunkName: "pages/nl/expositie" */))
const _267b7e9d = () => interopDefault(import('../pages/nl/filmpje-johan-mulder.vue' /* webpackChunkName: "pages/nl/filmpje-johan-mulder" */))
const _0852a90b = () => interopDefault(import('../pages/nl/giclees.vue' /* webpackChunkName: "pages/nl/giclees" */))
const _6cff0742 = () => interopDefault(import('../pages/nl/landschap.vue' /* webpackChunkName: "pages/nl/landschap" */))
const _e419259a = () => interopDefault(import('../pages/nl/opdrachten.vue' /* webpackChunkName: "pages/nl/opdrachten" */))
const _3c59b1a6 = () => interopDefault(import('../pages/nl/open-atelier.vue' /* webpackChunkName: "pages/nl/open-atelier" */))
const _8354f5cc = () => interopDefault(import('../pages/nl/schilderijen-toegepast.vue' /* webpackChunkName: "pages/nl/schilderijen-toegepast" */))
const _484abce5 = () => interopDefault(import('../pages/nl/stilleven.vue' /* webpackChunkName: "pages/nl/stilleven" */))
const _4b2be096 = () => interopDefault(import('../pages/nl/trompe-loeil.vue' /* webpackChunkName: "pages/nl/trompe-loeil" */))
const _095e1ace = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _8c051cc0,
    name: "en"
  }, {
    path: "/nl",
    component: _551a4716,
    name: "nl"
  }, {
    path: "/en/animals",
    component: _56746ac5,
    name: "en-animals"
  }, {
    path: "/en/commissioned",
    component: _bfd2ff28,
    name: "en-commissioned"
  }, {
    path: "/en/Contact",
    component: _f5bc6f64,
    name: "en-Contact"
  }, {
    path: "/en/exposition",
    component: _0ab0e9fe,
    name: "en-exposition"
  }, {
    path: "/en/giclees",
    component: _16e6c114,
    name: "en-giclees"
  }, {
    path: "/en/landscape",
    component: _35c89409,
    name: "en-landscape"
  }, {
    path: "/en/open-studio",
    component: _40b138f2,
    name: "en-open-studio"
  }, {
    path: "/en/paintings-applied",
    component: _6722cc2d,
    name: "en-paintings-applied"
  }, {
    path: "/en/stil-life",
    component: _8c929f9a,
    name: "en-stil-life"
  }, {
    path: "/en/trompe-loeil",
    component: _cd5b95ea,
    name: "en-trompe-loeil"
  }, {
    path: "/en/video-johan-mulder",
    component: _5dc0143e,
    name: "en-video-johan-mulder"
  }, {
    path: "/nl/Contact",
    component: _ce305c3a,
    name: "nl-Contact"
  }, {
    path: "/nl/dieren",
    component: _28ae9888,
    name: "nl-dieren"
  }, {
    path: "/nl/expositie",
    component: _d61ffa2a,
    name: "nl-expositie"
  }, {
    path: "/nl/filmpje-johan-mulder",
    component: _267b7e9d,
    name: "nl-filmpje-johan-mulder"
  }, {
    path: "/nl/giclees",
    component: _0852a90b,
    name: "nl-giclees"
  }, {
    path: "/nl/landschap",
    component: _6cff0742,
    name: "nl-landschap"
  }, {
    path: "/nl/opdrachten",
    component: _e419259a,
    name: "nl-opdrachten"
  }, {
    path: "/nl/open-atelier",
    component: _3c59b1a6,
    name: "nl-open-atelier"
  }, {
    path: "/nl/schilderijen-toegepast",
    component: _8354f5cc,
    name: "nl-schilderijen-toegepast"
  }, {
    path: "/nl/stilleven",
    component: _484abce5,
    name: "nl-stilleven"
  }, {
    path: "/nl/trompe-loeil",
    component: _4b2be096,
    name: "nl-trompe-loeil"
  }, {
    path: "/",
    component: _095e1ace,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
