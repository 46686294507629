




import Vue from 'vue';
export default Vue.extend({
  name: "ImageComponent",
  props: {
    src: {
      type: String,
      required: true
    }
  },
  methods: {
    onClick: (ev: MouseEvent) => {
      if (ev) {
        let el = ev.currentTarget as HTMLImageElement;
        window.open(el.src, "_blank");
      }
    }
  },
})
