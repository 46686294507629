




















import Vue from "vue";
import Banner from "~/components/layout/Banner.vue";
import Nav from "~/components/layout/Nav.vue";
import Menu from "~/components/layout/Menu.vue";

let cookie: string = document.cookie.substring(document.cookie.indexOf("lang=")+5, document.cookie.indexOf("lang=")+7);

export default Vue.extend({
  name: "Default",
  components: {
    "v-nav": Nav,
    "v-banner": Banner,
    "v-menu": Menu
  },
  data () {
    return {
      lang: cookie == "en" ? cookie : "nl"
    };
  },
  middleware({redirect, route, env, params}) {
    if (route.path.toLowerCase().includes("en")) {
      env.LANGUAGE = "en";
    } else if (route.path.toLowerCase().includes("nl")) {
      env.LANGUAGE = "nl";
    } else {
      env.LANGUAGE = "nl"
      redirect(`/nl${route.path}`);
    }
  },
  methods: {
    setCookie(cname: string, cvalue: string, exdays: number) {
      var d = new Date();
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
      var expires = "expires="+ d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    reload () {
      location.reload();
    }
  },
  computed: {
    isCookieEmpty(): boolean {
      return document.cookie.length === 0;
    }
  },
  watch: {
    'lang': function (lang) {
      this.setCookie("lang", lang, 10);
    }
  }
});
