









import Vue from "vue";

export default Vue.extend({
  name: "Link",
  props: {
    external: {
      type: Boolean,
      required: true
    },
    href: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  data () {
    return {

    };
  },
  computed: {

  },
  methods: {
    capitalize: (str: string) => {
      return (
        str
          .trim()
          .split(" ")
          .map(s => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ")
      );
    }
  }
});
