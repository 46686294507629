








import Vue from 'vue';
import ImageComponent from "~/components/ImageComponent.vue";
export default Vue.extend({
  name: "Banner",
  components: {
    "v-img": ImageComponent
  },
  data () {
    return {
      profile_pic: require("~/assets/images/portret_johan.jpg")
    };
  }
});
