













import Vue from "vue";
import Link from "../actions/Link.vue";
import { ILink } from "../../interfaces";

interface IData {
  links: Array<ILink>
}

export default Vue.extend({
  name: "Menu",
  components: {
    "v-link": Link
  },
  data () {
    return {
      links: [
        {
          name: "open atelier",
          href: "/nl/open-atelier",
          en: {
            name: "open studio",
            href: "/en/open-studio"
          }
        },
        {
          name: "trompe-l'oeil",
          href: "/nl/trompe-loeil",
          en: {
            name: "trompe-l'oeil",
            href: "/en/trompe-loeil"
          }
        },
        {
          name: "dieren",
          href: "/nl/dieren",
          en: {
            name: "animals",
            href: "/en/animals"
          }
        },
        {
          name: "landschap",
          href: "/nl/landschap",
          en: {
            name: "landscape",
            href: "/en/landscape"
          }
        },
        {
          name: "stilleven",
          href: "/nl/stilleven",
          en: {
            name: "stillife",
            href: "/en/stil-life"
          }
        },
        {
          name: "expositie",
          href: "/nl/expositie",
          en: {
            name: "exposition",
            href: "/en/exposition"
          }
        },
        {
          name: "opdrachten",
          href: "/nl/opdrachten",
          en: {
            name: "commissioned",
            href: "/en/commissioned"
          }
        },
        {
          name: "filmpje Johan Mulder",
          href: "/nl/filmpje-johan-mulder",
          en: {
            name: "video Johan Mulder",
            href: "/en/video-johan-mulder"
          }
        },
        {
          name: "giclees",
          href: "/nl/giclees",
          en: {
            name: "giclees",
            href: "/en/giclees"
          }
        },
        {
          name: "Schilderijen toegepast",
          href: "/nl/schilderijen-toegepast",
          en: {
            name: "paintings applied",
            href: "/en/paintings-applied"
          }
        }
      ]
    };
  }
});
