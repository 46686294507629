




















import Vue from "vue";
import Link from "../actions/Link.vue";
import { ILink } from "../../interfaces";

interface IData {
  links: Array<ILink>;
}

export default Vue.extend({
  name: "Nav",
  components: {
    "v-link": Link
  },
  data () {
    const data: IData = {
      links: [
        {
          name: "johan mulder",
          href: `/nl`,
          external: false,
          en: {
            href: "/en"
          },
          active: [
            "en",
            "nl"
          ]
        },
        {
          name: "contact",
          href: `/nl/contact`,
          external: false,
          en: {
            href: "/en/contact"
          }
        },
        {
          name: "facebook",
          href: "https://www.facebook.com/Atelier-Johan-Mulder-128593930550932/",
          external: true
        }
      ]
    };
    return data;
  },
  methods: {
    changeLang: function () {
      this.$parent.$data.lang === "nl" ? this.$parent.$data.lang = "en" : this.$parent.$data.lang = "nl";
      this.$router.replace(`/${this.$parent.$data.lang}`);
    }
  },
  watch: {
    'this.$parent.$data.lang': function (lang) {

    }
  }
});
